<template>
    <Grid :column-num="3">
        <GridItem @click="gotoRouter('/booksList', '中医古籍')">中医<br/>古籍</GridItem>
        <GridItem text="文字"></GridItem>
        <GridItem><router-link to="/booksList">文字1</router-link></GridItem>
    </Grid>
    <Row class="lzy-index-content">
        <Col :span="24" class="lzy-index" v-for="item in homeRows">
            <h2>{{item.title}}</h2>
            <view class="lzy-view" v-for="c in homeRowData[item.strId]" @click.prevent="gotoDetail(item, c)">
                <Ellipsis :content="c.title" direction="end"/>
            </view>
        </Col>
    </Row>
</template>

<script>
    import { reactive, toRefs, inject, onMounted } from "vue"
    import { useRouter, } from 'vue-router'
    import { Grid, GridItem, Row, Col, Ellipsis, } from "@nutui/nutui"

    import { homeRows, uriConstant } from '@/utils/SysConstant'
    import { post } from '@/utils/HttpClient'
    import { api } from '@/utils/ApiURI'

    export default {
        name: "MIndexCenter",
        components:{
            Grid,
            GridItem,
            Row,
            Col,
            Ellipsis,
        },
        setup() {
            const moolngMIndexData = inject('moolngMIndexData')
            const router = useRouter()
            const data = reactive({
                homeRowData:{},
            })

            const gotoRouter = (path, title) =>{
                moolngMIndexData.navBarTitle = title
                router.push(path)
            }

            const getHomeRow = () => {
                post(api.getHomeRowData, {pageSize:10, pageNo:1}).then(res=>{
                    console.log('getHomeRow===> ', res)
                    data.homeRowData = res.result
                })
            }

            const gotoDetail = (categoryInfo, dataInfo) =>{
                const routerLink = uriConstant[categoryInfo.category] + dataInfo.strId
                console.info('goto routerLink==>', routerLink)
                router.push(routerLink)
            }


            onMounted(()=>{
                getHomeRow()
            })

            return{
                ...toRefs(data),
                gotoRouter,
                homeRows,
                gotoDetail,
            }
        }
    }
</script>

<style scoped>
    .lzy-index h2 {
        /*margin-top: 20px;*/
        /*margin-bottom: 10px;*/
        font-size: 18px;
        color: #909ca4;
        padding: 0 10px;
        font-weight: 400;
    }
    /*.lzy-index-content {*/
    /*    margin: 30px 0px;*/
    /*}*/
    .nut-ellipsis {
        padding-left: 10px;
    }
    .lzy-view {
        /*line-height: 30px;*/
        /*display: block;*/
        /*width: 100%;*/

        /*align-items: center;*/
        /*text-align: left;*/
        /*justify-center: center;*/
        /*justify-content: left;*/
        width: 100%;
        height: 40px;
        line-height: 40px;
        /*padding-left: 60px;*/
        /*margin-bottom: 10px;*/
        /*background-color: #f4a8b6;*/
        /*border-radius: 10px;*/
        /*border-style: solid;*/
        /*border-bottom: 1px solid red;*/
        /*border-width: 0.1px;*/
    }
</style>