<template>
    <Cell>
        <view class="">
            <view class="m-title">{{title}}</view>
            <div class="m-title-desc" v-html="content"></div>
        </view>
    </Cell>
</template>

<script>
    import { Cell } from "@nutui/nutui"


    export default {
        name: "MCellHtml",
        components:{
            Cell,
        },
        props:{
            title: String,
            content: String,
        }
    }
</script>

<style scoped>
    .m-title {
        font-weight: bold;
        font-size: 25px;
    }
    .m-title-desc {
        margin-top: 10px;
        letter-spacing:2px;
        font-size: 25px;
        line-height: 28px;
        /*font-weight: normal;*/
    }
</style>