<template>
    <List :height="50" :listData="booksResult.data" @scroll-bottom="handleScroll" >
        <template v-slot="{ item, index }">
            <div class="list-item" @click="gotoBook(item.strId)">
                {{ item.name }}
            </div>
        </template>
    </List>
</template>

<script>
    import { List, } from "@nutui/nutui"
    import { onMounted, reactive, toRefs, inject } from 'vue';

    import { post } from '@/utils/HttpClient'
    import { api } from '@/utils/ApiURI'
    import router from "../../router";


    export default {
        name: "MBooksList",
        components:{
            List,
        },
        setup(){

            const  moolngMIndex = inject('moolngMIndexData')

            const data = reactive({
                // count: new Array(100).fill(0),
                // count: [],
                booksResult:{
                    data:[],
                    pageNo:1,
                    pageSize:20,
                },

            });

            const handleScroll = () => {
                // let arr = new Array(100).fill(0);
                // const len = state.count.length;
                // state.count = state.count.concat(arr.map((item, index) => len + index + 1));
                getBooksList()

            };

            const gotoBook = (strId)=>{
                router.push(`/books/${strId}/INIT`)
            }

            const getBooksList = () =>{
                const queryObj = {pageNo:data.booksResult.pageNo, pageSize:data.booksResult.pageSize}
                post(api.getBooksList, queryObj).then(res=>{
                    console.log('getBooksList', res)
                    if(res.status === 200) {
                        data.booksResult = res.result
                        // moolngHomePageData.pageListResultData = res.result
                        // data.searchResult = thisData
                        // router.push('/search')
                    }
                })
            }

            onMounted(() => {
                moolngMIndex.type = 'BooksList'
                getBooksList()
                // data.count = data.count.map((item, index) => index + 1);
            })

            return {
                ...toRefs(data),
                handleScroll,
                gotoBook,
            }
        }
    }
</script>

<style scoped>
    .list-item {
        /*display: flex;*/
        align-items: center;
        /*text-align: left;*/
        /*justify-center: center;*/
        justify-content: left;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        margin-bottom: 10px;
        /*background-color: #f4a8b6;*/
        /*border-radius: 10px;*/
        /*border-style: solid;*/
        border-bottom: 1px solid red;
        /*border-width: 0.1px;*/
    }
</style>