export const homeRows = [
    {
        strId:"bf972b6c5a4133d1",
        title:"中医常识",
        dataPath:"/web/article/getRowData",
        pageDataPath:"/web/article/getPageList",
        morePath:"/list/bf972b6c5a4133d1",
        category:"article",
        // routerPath:()=>{
        //     return uriConstant["article"]
        // }
    },
    {
        strId:"7a9225cf45f359ef",
        title:"神农本草经",
        dataPath:"/web/medicine/getRowData",
        pageDataPath:"/web/medicine/getPageList",
        morePath:"/list/7a9225cf45f359ef",
        category:"medicine",
        // routerPath:()=>{
        //     return uriConstant[this.category]
        // }

    },
    {
        strId:"71e8a2242fc3a467",
        title:"倪师-医案",
        dataPath:"/web/consilia/getRowData",
        pageDataPath:"/web/consilia/getPageList",
        morePath:"/list/71e8a2242fc3a467",
        category:"consilia",
        // routerPath:()=>{
        //     return uriConstant[this.category]
        // }
    },

]

export const uriConstant = {
        'article' : '/detail/default/',
        'medicine' : '/detail/medicine/',
        'consilia' : '/detail/consilia/'
}

export const api = {
    search : "/web/search",
    getOption : "/web/getOption/",
    getHomeRowData : "/web/home/getRowData",
}
