<template>

    <Navbar id="nav" @on-click-back="back" @on-click-title="title" :title="navBarTitle"
            placeholder="true"
            fixed="true"
            border="true"
            safe-area-inset-top="true"
            z-index="999"
    >
        <template #left>
            <Home v-if="type === 'BooksList' || type === 'SearchList'" @click="goHome">首页</Home>
            <Left v-else-if="type === 'Books'" @click="gotoRouter('/booksList')"></Left>
            <Left v-else-if="type === 'DetailArticle'" @click="doBack">返回</Left>
        </template>
        <template #right>
            <div v-if="type === 'Books'" @click="showBookCatalogue">目录</div>
        </template>
    </Navbar>
    <Searchbar v-model="searchKeyword" @search="doSearch" @click-input="editSearchInput" style="padding-top: 48px">
        <template v-slot:leftin>
            <Search2 />
        </template>
        <template v-slot:rightout>
            <div  @click="doSearch">搜索</div>
        </template>
    </Searchbar>

    <Noticebar :scrollable="true" :height='100' :speed='30' :standTime='1000' :list=[]>
        <template #default>
            {{noticeBarText}}
        </template>
    </Noticebar>

    <router-view></router-view>
    <Row>
        <Col :span="24">
            <view class="lzy-warning">身体不适时请至正规医院就诊！勿延误！</view>
            <view class="lzy-warning">站内信息时效及准确性不足！仅作爱好者休闲。</view>
            <view class="lzy-warning">本站是个人因爱好而建，非医药行业，不看病、不卖药、不授课、不参与任何商业！</view>
        </Col>
        <Col :span="24">
            <view class="lzy-footer">
                <view>Copyright © 2022-2023 论中医</view>
                <view><a href="https://beian.miit.gov.cn/">{{beiAnHao}}</a></view>
                <view>声明: 本网站部分内容从互联网收集整理，如果您发现不合适的内容，请联系我们进行处理，谢谢合作！</view>
            </view>
        </Col>
    </Row>
</template>

<script>
    import { Left, Home, Search2} from '@nutui/icons-vue'

    import { Navbar, Searchbar, Row, Col, Noticebar} from "@nutui/nutui"
    import { reactive, toRefs, provide, watch, computed } from "vue"
    import { useRouter, useRoute } from 'vue-router'

    import { api } from '@/utils/ApiURI'
    import { post } from '@/utils/HttpClient'

    export default {
        name: "MIndex",
        components:{
            Navbar,
            Searchbar,
            Left,
            Home,
            Search2,
            Row,
            Col,
            Noticebar,
        },
        setup() {
            const router = useRouter()
            const route = useRoute()
            const data = reactive({
                horseLamp1: [
                    '身体不适时请至正规医院就诊！勿延误！',
                    '站内信息时效及准确性不足！仅作爱好者休闲。',
                    '本站是个人因爱好而建，非医药行业，不看病、不卖药、不授课、不参与任何商业！',
                ],
                noticeBarText:'身体不适时请至正规医院就诊！勿延误！站内信息时效及准确性不足！仅作爱好者休闲。本站是个人因爱好而建，非医药行业，不看病、不卖药、不授课、不参与任何商业！',
                beiAnHao:'京ICP备12014686号-3',
                navBarTitle:'论中医',
                upNavBarTitle:'',
                // searchValue:'综合搜索',
                searchValueEditFlag:true,
                showSearchFlag:true,
                type: 'Home',
                sideNavBarObj:{
                    showFlag:false
                },
                searchKeyword:'综合搜索',
                selectValue : 'all',
                selectCategoryValue : 'all',
                currentSearchKeyword:"",
                // searchPageNo:1,
                // searchPageSize:10,
                pageListResultData:{
                    data:[],
                    pageNo:0,
                    pageSize:0,
                },
            })

            provide('moolngMIndexData', data)

            const doBack = () => {
                data.navBarTitle = '论中医'
                const path = route.path
                if(path === '/') {
                    return
                }
                router.back()
            }

            const goHome = () => {
                data.navBarTitle = '论中医'
                data.searchKeyword = '综合搜索'
                data.searchValueEditFlag = true
                data.currentSearchKeyword = '论中医'
                router.push('/')
            }

            const editSearchInput = () => {
                if(data.searchValueEditFlag) {
                    data.searchKeyword = ''
                    data.searchValueEditFlag = false
                }
                console.log('editSearchInput')
                // router.push('/')
            }

            const showBookCatalogue = () => {
                data.sideNavBarObj.showFlag = true
            }

            const toSearch = () => {
                data.showSearchFlag = false
                router.push('/search')
            }

            const gotoRouter = (routerPath) => {
                router.push(routerPath)
            }

            function doSearch() {
                console.log('doSearch', data.searchKeyword)
                if(data.searchKeyword === '' || (data.searchKeyword === data.currentSearchKeyword && data.pageListResultData.pageNo === 1)) {
                    return
                }
                data.currentSearchKeyword = data.searchKeyword
                let searchObj = {
                    'searchKeyword': data.searchKeyword,
                    'searchFieldList': [data.selectValue],
                    'category':data.selectCategoryValue,
                    'pageNo':1,
                    'pageSize': 15,
                }
                post(api.search, searchObj).then(res=>{
                    if(res.status === 200) {
                        router.push('/searchList')
                        data.pageListResultData = res.result
                        data.navBarTitle = '关键字:' + data.searchKeyword
                    }
                })
            }

            // computed(()=>{
            //
            // })

            // watch(()=>data.type,(o, n)=>{
            //     if('' === data.type ) {
            //
            //     }else {
            //         data.type = 'Home'
            //     }
            // })


            return{
                ...toRefs(data),
                doBack,
                goHome,
                toSearch,
                showBookCatalogue,
                editSearchInput,
                doSearch,
                gotoRouter,
            }
        }
    }

</script>

<style scoped >
    #nav {
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
    .lzy-footer {
        padding: 10px 0 0px 0;
        display: block;
        /*background-color : #f0f2f5;*/
        text-align: center;
        /*padding: 30px 0 30px 0;*/
    }
    .lzy-footer view {
        display: block;
        padding: 0px 0 10px 0;
    }
    .lzy-warning {
        display: block;
        color: red;
        text-align: left;
        font-weight: bold;
        font-size: 18px;
    }
    .lzy-warning view {
        display: block;
        padding: 0px 0 10px 0;
    }
</style>
