<template>
    <Cell title="姓名" :desc="info.name" class="title"/>
    <Cell title="性别" :desc="info.sex" class="title"/>
    <Cell title="年龄" :desc="info.age" class="title"/>
    <Cell title="体型" :desc="info.somatotype" class="title"/>
    <Cell title="来诊日期" :desc="info.visitDate" class="title"/>
    <MCellHtml title="来诊原因" :content="info.visitReason"></MCellHtml>
    <MCellHtml title="问诊" :content="info.inquiry"></MCellHtml>
    <MCellHtml title="望诊" :content="info.visit"></MCellHtml>
    <MCellHtml title="脉诊" :content="info.sphygmus"></MCellHtml>
    <MCellHtml title="舌诊" :content="info.tongueVisit"></MCellHtml>
    <MCellHtml title="耳诊" :content="info.earVisit"></MCellHtml>
    <MCellHtml title="诊断" :content="info.diagnose"></MCellHtml>
    <MCellHtml title="特殊诊断" :content="info.specialVisit"></MCellHtml>
    <MCellHtml title="针灸处方" :content="info.acupuncturePrescription"></MCellHtml>
    <MCellHtml title="中药处方" :content="info.medicinePrescription"></MCellHtml>
    <MCellHtml title="解说" :content="info.narrate"></MCellHtml>
    <MCellHtml title="备注" :content="info.remarks"></MCellHtml>
</template>

<script>
    import { Cell } from "@nutui/nutui"
    import {onMounted, reactive, inject, toRefs} from 'vue'
    import { get } from '@/utils/HttpClient'
    // import {onMounted, reactive, inject, toRefs} from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import MCellHtml from './MCellHtml'

    export default {
        name: "ConsiliaDetailArticle",
        components:{
            Cell,
            MCellHtml,
        },
        setup(){
            // const router = useRouter()
            const route = useRoute()
            const  moolngMIndexData = inject('moolngMIndexData')
            let data = reactive({
                info:{}
            })

            onMounted(()=>{
                moolngMIndexData.type = 'DetailArticle'
                let strId = route.params.strId
                get(`/app/consilia/view/${strId}`).then(res=>{
                    data.info = res.result
                    console.info(`/app/consilia/view/${strId}`, data.info)
                    // setDetailName(data.info.name)
                    // setHeadElement(data.info);
                })
            })

            return {
                ...toRefs(data)
            }
        }
    }
</script>

<style scoped>
    /*.title-desc {*/
    /*    letter-spacing:2px;*/
    /*    font-weight: normal;*/
    /*}*/
    .title {
        font-weight: bold;
    }
</style>