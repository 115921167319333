<template>
    <template v-if="categoryType === 'medicine'">
        <MedicineDetailArticle></MedicineDetailArticle>
    </template>
    <template v-else-if="categoryType === 'consilia'">
        <ConsiliaDetailArticle></ConsiliaDetailArticle>
    </template>
    <template v-else>
        <DefaultDetailArticle></DefaultDetailArticle>
    </template>
</template>

<script>
    import { computed } from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import ConsiliaDetailArticle from "@/views/detail/ConsiliaDetailArticle";
    import MedicineDetailArticle from "@/views/detail/MedicineDetailArticle";
    import DefaultDetailArticle from "@/views/detail/DefaultDetailArticle";

    export default {
        name: "DetailRouter",
        components:{
            ConsiliaDetailArticle,
            MedicineDetailArticle,
            DefaultDetailArticle,
        },
        setup(){
            const route =  useRoute()
            let categoryType = computed(()=>{
                return  route.params.category
            })

            return {
                categoryType
            }
        }
    }
</script>

<style scoped>

</style>