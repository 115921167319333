<template>
    <Popup position="right" v-model:visible="popupShowFlag" :style="{ width, height }">
        <SideNavbar>
            <SubSideNavbar v-for="item in navs" :key="item.strId" :title="item.name" :ikey="item.strId">
                <SideNavbarItem @click="gotoArticle(citem.strId)" v-for="citem in item.subList" :key="citem.strId" :title="citem.name"></SideNavbarItem>
            </SubSideNavbar>
        </SideNavbar>
    </Popup>
    <view>
<!--        <PullRefresh v-model="refreshFlag" @refresh="refreshFun">-->
            <view class="option" v-html="articleInfo.content"></view>
<!--            <InfiniteLoading-->
<!--                    v-model="infinityValue"-->
<!--                    load-txt="Loading..."-->
<!--                    load-more-txt="没有啦~"-->
<!--                    :has-more="hasMore"-->
<!--                    :threshold="2"-->
<!--                    @load-more="loadMore"-->
<!--            >-->
                <view class="lzy-button">
                    <Button type="default" size="large" @click="doUpFun">上一篇</Button>
                    <br/>
                    <Button type="default" size="large" @click="doDownFun">下一篇</Button>
                </view>
<!--            </InfiniteLoading>-->
<!--        </PullRefresh>-->
        <Backtop></Backtop>
    </view>
</template>

<script>
    import { showToast, Navbar, Popup, SideNavbar, SubSideNavbar, SideNavbarItem, InfiniteLoading, PullRefresh, Button, Backtop} from '@nutui/nutui'
    import { ShareN, RectLeft, Home } from '@nutui/icons-vue'
    import {reactive, toRefs, inject, onMounted, watch } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import { get } from '@/utils/HttpClient'
    import { api } from '@/utils/ApiURI'



    export default {
        name: "DefaultDetailArticle",
        components:{
            Navbar,
            Popup,
            SideNavbar,
            SubSideNavbar,
            SideNavbarItem,
            InfiniteLoading,
            PullRefresh,
            Button,
            Backtop,
            ShareN,
            RectLeft,
            Home,
        },
        setup(){
            const  moolngMIndexData = inject('moolngMIndexData')
            const router = useRouter()
            const route = useRoute()
            const data = reactive({
                refreshFlag: false,
                popupShowFlag: false,
                infinityValue: true,
                hasMore: true,
                width: '80%',
                height: '100%',
                navBarTitle:'',
                navs: [],
                loadNavsFlag:false,
                articleInfo:{},
                currentStrId:'',
                articleEndFlag:false,
            });

            const refreshFun = () => {
                console.log('refreshFun')
                doUpFun()
                setTimeout(() => {
                    data.refreshFlag = false;
                }, 2000);

            };
            /// nut-overlay
            const loadMore = () => {
                console.log('loadMore')
                setTimeout(() => {
                    doDownFun()
                    data.infinityValue = false
                }, 300);
            };

            const doUpFun = ()=>{
                console.log('doUpFun')
                doGetArticle(data.currentStrId, 'UP')
                if(data.articleEndFlag) {
                    data.articleEndFlag = false
                }
            }

            const doDownFun = ()=>{
                console.log('doDownFun')
                doGetArticle(data.currentStrId, 'DOWN')
            }

            const gotoArticle = (strId) => {
                doGetArticle(strId, 'OTHER')
                moolngMIndexData.sideNavBarObj.showFlag = false
            }
            const textToast = (msg) => {
                showToast.text(msg);
            };
            const doGetArticle = (strId, serialNumType) => {
                if(data.articleEndFlag) {
                    textToast('已是最后一篇')
                    return
                }
                data.articleHasMore = false
                data.articleInfinityValue = false
                get(`${api.getArticleInfo}${strId}/${serialNumType}`).then(res=>{
                    console.log(res)
                    data.articleInfinityValue = true
                    if(res.result.strId === '') {
                        data.articleEndFlag = true
                        data.articleHasMore = false
                        textToast('已是最后一篇')
                        return
                    }
                    data.articleHasMore = true
                    data.articleInfo = res.result
                    data.currentStrId = res.result.strId
                    moolngMIndexData.navBarTitle = res.result.title
                    document.querySelector('#app').scrollIntoView(true)
                })
            }

            onMounted(()=>{
                moolngMIndexData.type = 'DetailArticle'
                gotoArticle(route.params.strId, route.params.serialNumType)
            })

            return {
                ...toRefs(data),
                // ...toRefs(state),
                moolngMIndexData,
                gotoArticle,
                loadMore,
                refreshFun,
                doUpFun,
                doDownFun,
            }
        }
    }

</script>

<style scoped >
    .option {
        width: 100%;
        line-height: 30px;
        letter-spacing:2px;
        font-size: 25px;
    }
    .lzy-button {
        /*padding: 150px 0px 0px 0px;*/
    }
</style>
