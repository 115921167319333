// 该文件专门用于创建整个应用的路由器
import { createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
//引入组件
import MIndex from "@/views/MIndex";
import MSearch from "@/views/MSearch";
// import MArticle from "@/views/center/MArticle";
import MIndexCenter from "@/views/center/MIndexCenter";
import MBooksList from "@/views/books/MBooksList";
import MBooks from "@/views/books/MBooks";

import MSearchList from "@/views/list/MSearchList";
import DetailRouter from "@/views/tools/DetailRouter";


//创建并暴露一个路由器
const routes = [
    {
        path:'/',
        component:MIndex,
        redirect:"/indexCenter"
    },
    {
        path:'/searchList',
        component:MSearchList,
    },
    {
        path:'/detail/:category/:strId',
        component:DetailRouter,
    },
    // {
    //     path:'/consilia/detail/:strId',
    //     component:ConsiliaDetailArticle,
    // },
    {
        path:'/indexCenter',
        component:MIndexCenter,
        children:[]
    },
    {
        path:'/booksList',
        component:MBooksList,
        children:[]
    },
    {
        path:'/books/:strId/:serialNumType',
        component:MBooks,
        children:[
            {
                path:'/books/:strId',
                component:MBooks,
                children:[]
            },
        ]
    },
]

// 动态配置history
function getHistory() {
    console.log('getHistory', process.env.NODE_ENV)
    switch (process.env.NODE_ENV) {
        // case 'development':
        //     // 本地测试环境
        //     return  createWebHistory()
        case 'test':
            // 本地测试环境
            return  createWebHistory()
        case 'production':
            // 生产环境
            return  createWebHistory()
        default:
            // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
            // 开发环境
            return createWebHashHistory();
    }
}

const router = createRouter({
    history: getHistory(),
    routes, // `routes: routes` 的缩写
})

export default router;