<template>
<!--    <List :height="50" :listData="searchListResult.data" @scroll-bottom="handleScroll" >-->
<!--        <template v-slot="{ item, index }">-->
<!--            <div class="list-item" @click="gotoBook(item.strId)">-->
<!--                {{ item.title }}-->
<!--            </div>-->
<!--        </template>-->
<!--    </List>-->
    <view class="list-box">
        <PullRefresh v-model="refreshFlag" @refresh="handleScrollUp" pull-distance="200">
            <InfiniteLoading
                    v-model="infinityValue"
                    :has-more="hasMore"
                    @load-more="handleScrollDown"
                    threshold="2"
            >
                <view class="infiniteLi" v-for="(item, index) in searchListResult.data" :key="index" @click="gotoDetail(item.strId, item.category)">{{item.title}}</view>
            </InfiniteLoading>
        </PullRefresh>
    </view>
</template>

<script>
    import { InfiniteLoading, PullRefresh, } from "@nutui/nutui"
    import { onMounted, reactive, toRefs, inject, watch } from 'vue';
    import { useRouter } from 'vue-router'

    import { post } from '@/utils/HttpClient'
    import { api } from '@/utils/ApiURI'

    export default {
        name: "MSearchList",
        components:{
            InfiniteLoading,
            PullRefresh,
        },
        setup(){
            const router = useRouter()
            const  moolngMIndexData = inject('moolngMIndexData')

            const data = reactive({
                // count: new Array(100).fill(0),
                // count: [],
                refreshFlag: false,
                infinityValue: false,
                hasMore: true,
                listEndFlag:false,
                searchListResult:{
                    data:[],
                    pageNo:0,
                    pageSize:0,
                },
            });

            const handleScrollDown = () => {
                console.log('handleScrollDown getBooksList', data.searchListResult.pageNo + 1)
                setTimeout(() => {
                    data.infinityValue = false
                }, 300);
                getBooksList(data.searchListResult.pageNo + 1, 15)
            };
            const handleScrollUp = () => {
                if(data.listEndFlag) {
                    data.listEndFlag = false
                }
                console.log('handleScrollUp getBooksList', data.searchListResult.pageNo - 1)
                // data.hasMore = true
                // data.infinityValue = true
                setTimeout(() => {
                    data.refreshFlag = false
                }, 300);
                const pageNo = data.searchListResult.pageNo - 1;
                if(pageNo === 0) {
                    return
                }
                getBooksList(pageNo, 15)
            };

            const gotoDetail = (strId, category)=>{
                // router.push(`/consilia/detail/${strId}`)
                // /detail/:category/:strId
                router.push(`/detail/${category}/${strId}`)
            }


            const gotoBook = (strId)=>{
                console.log('gotoBook strId ===>', strId)
                // router.push(`/books/${strId}/INIT`)
            }

            const getBooksList = (pageNo, pageSize) =>{
                console.log('doSearch', moolngMIndexData.searchKeyword)
                if(moolngMIndexData.searchKeyword === '') {
                    return
                }
                let searchObj = {
                    'searchKeyword': moolngMIndexData.searchKeyword,
                    'searchFieldList': [moolngMIndexData.selectValue],
                    'category':moolngMIndexData.selectCategoryValue,
                    'pageNo': pageNo,
                    'pageSize': pageSize,
                }
                post(api.search, searchObj).then(res=>{
                    console.log(`post ${api.search}`)
                    if(res.status === 200) {
                        moolngMIndexData.pageListResultData = res.result
                        if(res.result.data.length < pageSize) {
                            data.hasMore = false
                            data.listEndFlag = true
                        }
                        document.querySelector('#app').scrollIntoView(true)
                    }
                })

                // const queryObj = {pageNo:data.booksResult.pageNo, pageSize:data.booksResult.pageSize}
                // post(api.getBooksList, queryObj).then(res=>{
                //     console.log('getBooksList', res)
                //     if(res.status === 200) {
                //         data.booksResult = res.result
                //         // moolngHomePageData.pageListResultData = res.result
                //         // data.searchResult = thisData
                //         // router.push('/search')
                //     }
                // })
            }

            onMounted(() => {
                moolngMIndexData.type = 'SearchList'
                data.searchListResult = moolngMIndexData.pageListResultData
                // getBooksList()
                // data.count = data.count.map((item, index) => index + 1);
            })

            watch(()=>moolngMIndexData.pageListResultData,(o, n)=>{
                console.log('watch')
                data.searchListResult = moolngMIndexData.pageListResultData
            })

            return {
                ...toRefs(data),
                handleScrollUp,
                handleScrollDown,
                gotoBook,
                gotoDetail,
            }
        }
    }
</script>

<style scoped>
    /*.list-box {*/
    /*    margin: 30px 30px 30px 20px;*/
    /*}*/
    .infiniteLi {
        font-size: 26px;
        color: #333;
        padding: 12px 0;
        border-bottom: 1px solid #eee;
        display: block;
        /*padding-left: 10px;*/
    }

    .list-item {
        /*display: flex;*/
        align-items: center;
        /*text-align: left;*/
        /*justify-center: center;*/
        justify-content: left;
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        margin-bottom: 10px;
        /*background-color: #f4a8b6;*/
        /*border-radius: 10px;*/
        /*border-style: solid;*/
        border-bottom: 1px solid red;
        /*border-width: 0.1px;*/
    }
</style>