<template>
        <MIndex></MIndex>
</template>

<script>
    import MIndex from "./views/MIndex";
    export default {
        components:{
            MIndex,
        }
    }

</script>

<style >
    :root {
        --nut-navbar-title-font: 25px;
        --nut-navbar-margin-bottom: 5px;
        --nut-searchbar-padding: 9px 5px 9px 5px;
        --nut-cell-title-font: 20px;
        --nut-cell-title-desc-font: 16xp;
        /*--nut-button-border-width:50px;*/
        --nut-grid-item-text-font-size:20px;
        --nut-collapse-item-font-size:20px;
        --nut-noticebar-font-size:20px;
        --nut-noticebar-wrapable-padding:8px 0px;
    }
</style>
