<template>
    <Collapse>
        <CollapseItem :name="1" title="图片介绍">
            <Grid>
                <MImage imageText="原生态图片" :imageURL="info.originalEcologicalPic" :width="windowWidth" :height="windowWidth" ></MImage>
                <MImage imageText="未加工图片" :imageURL="info.rawPic" :width="windowWidth" :height="windowWidth" ></MImage>
                <MImage imageText="已加工图片" :imageURL="info.processedPic" :width="windowWidth" :height="windowWidth" ></MImage>
<!--                <GridItem text="原生态图片">-->
<!--                    <NutImage :src="info.originalEcologicalPic" :width="windowWidth" :height="windowWidth" showError>-->
<!--                        <template #error>-->
<!--                            <Image width="64px" height="64px" name="image" />-->
<!--                        </template>-->
<!--                    </NutImage>-->
<!--                </GridItem>-->
<!--                <GridItem text="未加工图片">-->
<!--                    <NutImage :src="info.rawPic" :width="windowWidth" :height="windowWidth" showError>-->
<!--                        <template #error>-->
<!--                            <Image width="64px" height="64px" name="image" />-->
<!--                        </template>-->
<!--                    </NutImage>-->
<!--                </GridItem>-->
<!--                <GridItem text="已加工图片">-->
<!--                    <NutImage :src="info.processedPic" :width="windowWidth" :height="windowWidth" showError>-->
<!--                        <template #error>-->
<!--                            <Image width="64px" height="64px" name="image" />-->
<!--                        </template>-->
<!--                    </NutImage>-->
<!--                </GridItem>-->
            </Grid>
        </CollapseItem>
        <CollapseItem :name="2" title="视频介绍">
            <iframe v-if="info.physicalCommentaryVideo != ''"
                    :width="windowWidth"
                    :height="windowHeight"
                    :src="info.physicalCommentaryVideo"
                    scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true">
            </iframe>
            <span v-else style="text-align: center;font-size: 30px">视频数据维护中...</span>
        </CollapseItem>
        <CollapseItem :name="2" title="倪师讲解">
            <iframe v-if="info.physicalExplainVideo != ''"
                    :width="windowWidth"
                    :height="windowHeight"
                    :src="`${info.physicalExplainVideo}&t=${info.explainVideoTimeSize}`"
                    scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true">
            </iframe>
            <span v-else style="text-align: center;font-size: 30px">视频数据维护中...</span>
        </CollapseItem>
    </Collapse>

    <MCellHtml title="【别名】" :content="info.alias"/>
    <MCellHtml title="【本经原文】" :content="info.originalText"/>
    <MCellHtml title="【产地】" :content="info.origin"/>
    <MCellHtml title="【性味】" :content="info.natureTaste"/>
    <MCellHtml title="【主治】" :content="info.indications"/>
    <MCellHtml title="摘要" :content="info.digest"/>
    <MCellHtml title="【用量】" :content="info.dosage"/>
    <MCellHtml title="【禁忌】" :content="info.contraindication"/>
    <MCellHtml title="【炮制】" :content="info.processing"/>
    <MCellHtml title="【附方】" :content="info.supplementaryFormula"/>
    <Backtop></Backtop>
</template>

<script>
    import { Tabs, TabPane, Grid, GridItem, Image as NutImage, Collapse, CollapseItem, Backtop } from "@nutui/nutui"
    import { Image } from '@nutui/icons-vue'
    import {onMounted, reactive, inject, toRefs, computed} from 'vue'

    import { get } from '@/utils/HttpClient'
    import MCellHtml from "@/views/detail/MCellHtml"
    import MImage from "@/views/detail/MImage"

    // import {onMounted, reactive, inject, toRefs} from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    export default {
        name: "MedicineDetailArticle",
        components:{
            Tabs,
            TabPane,
            Grid,
            GridItem,
            NutImage,
            Image,
            MCellHtml,
            MImage,
            Collapse,
            CollapseItem,
            Backtop,
        },
        setup(){
            const route = useRoute()
            const router = useRouter()
            const  moolngMIndexData = inject('moolngMIndexData')
            const data = reactive({
                imgURL:'//img10.360buyimg.com/ling/jfs/t1/181258/24/10385/53029/60d04978Ef21f2d42/92baeb21f907cd24.jpg',
                searchTabKey:0,
                windowWidth: document.documentElement.clientWidth - 80,
                info:{},
            })

            const windowHeight = computed(()=>{
                console.log('windowWidth', data.windowWidth)
                return data.windowWidth * 0.62
            })

            onMounted(()=>{
                moolngMIndexData.type = 'DetailArticle'
                let strId = route.params.strId
                get(`/app/medicine/view/${strId}`).then(res=>{
                    // get('/getMenu').then(res=>{
                    console.log('DetailArticle.vue res ', res)
                    if(res.status === 200) {
                        data.info = res.result
                        console.info(`/web/medicine/view/${strId}`, data.info)
                        moolngMIndexData.navBarTitle = data.info.name
                        // setDetailName(data.info.name)
                        // setHeadElement(data.info)
                    }else if(res.status === '404') {
                        // 404
                        router.push('/detail404')
                    }
                })
            })

            return {
                ...toRefs(data),
                windowHeight,
            }
        }
    }
</script>

<style scoped>

</style>