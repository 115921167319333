<template>
    <GridItem :text="imageText">
        <NutImage :src="imageURL" :width="width" :height="height" showError>
            <template #error>
                <Image width="64px" height="64px" name="image" />
            </template>
        </NutImage>
    </GridItem>
</template>

<script>
    import { GridItem, Image as NutImage, } from "@nutui/nutui"
    import { Image } from '@nutui/icons-vue'

    export default {
        name: "MImage",
        components:{
            GridItem,
            NutImage,
            Image,
        },
        props:{
            imageText: String,
            imageURL: String,
            width: String,
            height: String,
        }
    }
</script>

<style scoped>

</style>