import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import "@nutui/nutui/dist/style.css"
// import 'amfe-flexible'

console.log(process.env)
createApp(App)
    .use(router)
    .mount('#app')
