<template>
        <Searchbar v-model="searchValue" autofocus="true" @search="doSearch">
            <template v-slot:leftout>
                <RectLeft @click="goHome" />
            </template>
<!--            <template v-slot:leftin>-->
<!--                <Search2 />-->
<!--            </template>-->
<!--            <template v-slot:rightin>-->
<!--                <Message/>-->
<!--            </template>-->
            <template v-slot:rightout>
                <div  @click="doSearch">搜索</div>
            </template>
        </Searchbar>
</template>

<script>
    import { RectLeft, Home, Search2, Message} from '@nutui/icons-vue'
    import { showToast, Searchbar, } from "@nutui/nutui"

    import { reactive, toRefs, inject } from "vue"
    import {useRouter} from "vue-router";

    export default {
        name: "MSearch",
        components:{
            Searchbar,
            RectLeft,
            Search2,
            Message,
        },
        setup() {
            const router = useRouter()

            const moolngMIndexData = inject('moolngMIndexData')

            const data = reactive({
                searchValue: '',
            })

            const goHome = () => {
                moolngMIndexData.navBarTitle = '论中医'
                moolngMIndexData.showSearchFlag = true
                router.push('/')
            }
            const doSearch = () => {
                showToast.text('开始查询');
            }

            return {
                ...toRefs(data),
                goHome,
                doSearch,
            }
        }
    }
</script>

<style scoped>

</style>