<template>
<!--    <Navbar id="nav" @on-click-back="back" @on-click-title="title" :title="navBarTitle">-->
<!--        <template #left>-->
<!--            <RectLeft @click="doBack"></RectLeft>-->
<!--        </template>-->
<!--        <template #right>-->
<!--            <div width="16px" @click="handleClick1">目录</div>-->
<!--        </template>-->
<!--    </Navbar>-->
    <Popup position="right" v-model:visible="popupShowFlag" :style="{ width, height }">
        <SideNavbar>
            <SubSideNavbar v-for="item in navs" :key="item.strId" :title="item.name" :ikey="item.strId">
                <SideNavbarItem @click="gotoArticle(citem.strId)" v-for="citem in item.subList" :key="citem.strId" :title="citem.name"></SideNavbarItem>
            </SubSideNavbar>
        </SideNavbar>
    </Popup>

    <view>
        <PullRefresh v-model="refreshFlag" @refresh="refreshFun">
            <view class="option" v-html="articleInfo.content"></view>
            <InfiniteLoading
                    v-model="infinityValue"
                    load-txt="Loading..."
                    load-more-txt="没有啦~"
                    :has-more="hasMore"
                    :threshold="2"
                    @load-more="loadMore"
            >
                <view class="lzy-button">
                    <Button type="default" size="large" @click="doDownFun">下一篇</Button>
                </view>
            </InfiniteLoading>
        </PullRefresh>
        <Backtop></Backtop>
    </view>





<!--    <router-view></router-view>-->

</template>

<script>
    import { showToast, Navbar, Popup, SideNavbar, SubSideNavbar, SideNavbarItem, InfiniteLoading, PullRefresh, Button, Backtop} from '@nutui/nutui'
    import { ShareN, RectLeft, Home } from '@nutui/icons-vue'
    import {reactive, toRefs, inject, onMounted, watch, onUnmounted} from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    import { get } from '@/utils/HttpClient'
    import { api } from '@/utils/ApiURI'



    export default {
        name: "MBooks",
        components:{
            Navbar,
            Popup,
            SideNavbar,
            SubSideNavbar,
            SideNavbarItem,
            InfiniteLoading,
            PullRefresh,
            Button,
            Backtop,
            ShareN,
            RectLeft,
            Home,
        },
        setup(){
            const  moolngMIndex = inject('moolngMIndexData')
            const router = useRouter()
            const route = useRoute()
            const data = reactive({
                refreshFlag: false,
                popupShowFlag: false,
                infinityValue: true,
                hasMore: true,
                width: '80%',
                height: '100%',
                navBarTitle:'',
                navs: [],
                loadNavsFlag:false,
                articleInfo:{},
                currentStrId:'',
                articleEndFlag:false,
                // articleHasMore:true,
                // articleInfinityValue:true,
                // upFun: myUpFun => {
                //     doUpFun()
                // },
                // downFun: myDownFun => {
                //     doDownFun()
                // },
            });

            const refreshFun = () => {
                console.log('refreshFun')
                doUpFun()
                setTimeout(() => {
                    data.refreshFlag = false;
                }, 2000);

            };
            /// nut-overlay
            const loadMore = () => {
                console.log('loadMore')
                setTimeout(() => {
                    doDownFun()
                    data.infinityValue = false
                }, 300);
            };
            const getBooksCatalogue = (navStrId) => {
                  if(!data.loadNavsFlag) {
                    get(`${api.getBooksCatalogueByNavStrId}${navStrId}`).then(res=>{
                        console.log(res)
                        data.navs = res.result
                        data.loadNavsFlag = true
                        // data.info = res.result
                        // console.info(`/web/article/view/${strId}`, data.info)
                        // setDetailName(data.info.title)
                        // setHeadElement(data.info);
                    })
                }
            }

            const doUpFun = ()=>{
                console.log('doUpFun')
                doGetArticle(data.currentStrId, 'UP')
                if(data.articleEndFlag) {
                    data.articleEndFlag = false
                }
            }

            const doDownFun = ()=>{
                console.log('doDownFun')
                doGetArticle(data.currentStrId, 'DOWN')
            }

            const gotoArticle = (strId, serialNumType) => {
                console.log('gotoArticle serialNumType == ', serialNumType)
                if('INIT' === serialNumType) {
                    getBooksCatalogue(strId)
                    doGetArticle(strId, serialNumType)
                }else {
                    doGetArticle(strId, 'OTHER')
                }
                moolngMIndex.sideNavBarObj.showFlag = false
                // router.push(`/article/${strId}/OTHER`)
                // state.show1 = false;
            }
            const textToast = (msg) => {
                showToast.text(msg);
            };
            const doGetArticle = (strId, serialNumType) => {
                if(data.articleEndFlag) {
                    textToast('已是最后一篇')
                    return
                }
                data.articleHasMore = false
                data.articleInfinityValue = false
                get(`${api.getArticleInfo}${strId}/${serialNumType}`).then(res=>{
                    console.log(res)
                    data.articleInfinityValue = true
                    if(res.result.strId === '') {
                        data.articleEndFlag = true
                        data.articleHasMore = false
                        textToast('已是最后一篇')
                        return
                    }
                    data.articleHasMore = true
                    data.articleInfo = res.result
                    moolngMIndex.navBarTitle = res.result.title
                    // data.navbarTitle = res.result.title
                    data.currentStrId = res.result.strId
                    router.push(`/books/${data.currentStrId}`)
                    document.querySelector('#app').scrollIntoView(true)
                })
            }

            const doBack = () => {
                const path = route.path
                if(path === '/') {
                    return
                }
                router.back()
            }

            const updateSideNavBarObjShowFlag = ()=>{
                moolngMIndex.sideNavBarObj.showFlag = false
            }

            onMounted(()=>{
                moolngMIndex.type = 'Books'
                // const strId =
                gotoArticle(route.params.strId, route.params.serialNumType)
                // 优化SideNavBar点击空白处关闭后无法再次打开问题
                const nutOverlay = document.querySelector('.nut-overlay')
                nutOverlay.addEventListener('click', updateSideNavBarObjShowFlag)
            })

            //
            onUnmounted(()=>{
                window.removeEventListener('click', updateSideNavBarObjShowFlag);
            })

            watch(()=>moolngMIndex.sideNavBarObj.showFlag, (n, o)=>{
                // console.log('moolngMIndex.sideNavBarObj.showFlag ===>', moolngMIndex.sideNavBarObj.showFlag)
                data.popupShowFlag = moolngMIndex.sideNavBarObj.showFlag
            })

            // watch(()=>data.articleHasMore, (n, o)=>{
            //     // data.articleHasMore = true
            //     if(data.articleEndFlag) {
            //         return
            //     }
            //     document.querySelector('#app').scrollIntoView(true)
            // })

            return {
                ...toRefs(data),
                // ...toRefs(state),
                moolngMIndex,
                gotoArticle,
                doBack,
                loadMore,
                refreshFun,
                doDownFun,
            }
        }
    }

</script>

<style scoped >
    .option {
        line-height: 30px;
        letter-spacing:2px;
        font-size: 25px;
    }
    .lzy-button {
        /*padding: 150px 0px 0px 0px;*/
    }
</style>
